import React, { useState, useEffect, useRef, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Top from './Top'
import Matches from './Matches'
import TeamStandings from './Standings'
import HeroBlock from './HeroBlock'
import TeamLineup from './Lineup'
import Players from './Players'
import Events from './Events'
import { SeriesStack } from '../Atoms'

import DesktopContext from '../../Layouts/Desktop/ctx'

import { useScrollPosition } from '@n8tb1t/use-scroll-position'

import './style.scss'

import axios from 'axios'
import qs from 'qs'
import { ENDPOINT } from '../../env'

const periods = [
    {
        label: '-',
        items: [
            {label: 'В этом году', _id: 'currentYear'},
            {label: 'За всё время', _id: 'overall'}
        ]
    }
]

const Club = ({ mediablock, matchesCarousel, standings }) => {
    const [root, setRoot] = useState(null)
    const [team, setTeam] = useState(null)
    const [tours, setTours] = useState(null)
    const [stiched, setStiched] = useState(false)
    const [loaded, setLoaded] = useState(null)
    const [colWidth, setColWidth] = useState(null)
    const [userScrolled, setUserScrolled] = useState(false)
    const [leftColOffset, setLeftColOffset] = useState(null)

    const { id } = useParams()

    const contentRef = useRef()
    const colRef = useRef()
    const leftColRef = useRef()
    const seriesRef = useRef()
    const playersRef = useRef()

    const ctx = useContext(DesktopContext)
    const { appUser } = ctx

    useEffect(() => {
        if(id) {
            setTeam(null)
            const { team } = qs.parse(window.location.search.slice(1))

            axios.get(`${ENDPOINT}client/club/${id}`)
                .then(resp => {
                    setRoot(resp.data)
                    if(resp.data.teams && resp.data.teams.length) {
                        setTeam(team && resp.data.teams.find(t => t._id === team) ? team : resp.data.teams.find(t => t.canonical)._id)
                    }
                })
        }
    }, [id])

    useEffect(() => {
        if(team) {
            axios.get(`${ENDPOINT}client/teamtours/${team}`)
                .then(resp => {
                    setTours(Object.keys(resp.data).reduce((acc, cat) => {
                        acc[cat] = resp.data[cat]
                        // acc[cat] = resp.data[cat].filter(t => !t.playoffOnly)
                        return acc
                    }, {}))
                })
            }
    }, [team])

    useEffect(() => {
        if(colRef && colRef.current && !colWidth) {
            setColWidth(colRef.current.clientWidth)
        }
    }, [colRef])

    useEffect(() => {
        if(leftColRef && leftColRef.current && !leftColOffset) {
            const rect = leftColRef.current.getBoundingClientRect()
            setLeftColOffset(rect.left)
        }
    }, [leftColRef])

    useScrollPosition(({ prevPos, currPos }) => {
        const pos = Math.abs(currPos.y)
        const elem = contentRef.current.offsetTop+50

        const seriesBottom = seriesRef.current.offsetTop+seriesRef.current.clientHeight
        const plrsBottom = playersRef.current.offsetTop+playersRef.current.clientHeight
        const wh = window.outerHeight

        if(!userScrolled) {
            setUserScrolled(true)
        }

        if((seriesBottom < wh) && userScrolled) {
            if(!stiched && (pos > elem)) {
                setStiched(true)
            }

            if(stiched && (pos < elem)) {
                setStiched(false)
            }
        }
    })

    // const { team } = qs.parse(window.location.search.slice(1))
    //
    // useEffect(() => {
    //     if(team && team !== loaded) {
    //         axios.get(`${ENDPOINT}client/teamtours/${team}`)
    //             .then(resp => {
    //                 setTours(resp.data)
    //                 setLoaded(team)
    //             })
    //     }
    // }, [])

    return  <div className='app-page'>
                {root ? (
                    <Helmet>
                        <title>{`${root.name} | Статистика и расписание матчей команды | OLE PARI`}</title>
                    </Helmet>
                ) : null}

                <div className="container">
                    <Top
                        data={root}
                        favorites={appUser ? appUser.favorites || [] : []}
                        appUserId={appUser ? appUser.firebaseTkn : null}
                        team={team}
                        setTeam={setTeam}
                    />

                    <div className={`cols${stiched ? ' stiched' : ''}`} ref={contentRef}>
                        <div className='col-primary'>
                            <div style={{padding: '0 10px'}}>
                                {ctx.bannerNode}
                            </div>

                            <Matches theme={matchesCarousel.theme} team={team} />

                            {team ? <HeroBlock teamId={team} theme={mediablock.scopedTheme} /> : null}

                            <div className='cols'>
                                <div
                                    className='col-equal left-filled'
                                    style={{
                                        background: 'var(--team-column-gradient)'
                                    }}
                                    ref={leftColRef}
                                >
                                    <TeamLineup
                                        teamId={team}
                                    />

                                    <Events
                                        teamId={team}
                                        teamName={root ? root.name : ''}
                                        offsetLeft={0 - leftColOffset}
                                    />

                                    <div
                                        className='perspective'
                                        style={{backgroundImage: `url(${root ? root.emblem : ''})`}}
                                    ></div>
                                </div>

                                <div className='col-equal' ref={playersRef}>
                                    <Players
                                        tours={tours ? periods.concat(Object.keys(tours).map(k => ({
                                            label: k === 'active' ? 'Текущие' : 'Завершенные',
                                            items: tours[k].map(t => ({
                                                label: `${t.name} (${t.league}, ${t.season})`,
                                                _id: t._id
                                            }))
                                        }))) : null}
                                        teamId={team}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-secondary' ref={colRef}>
                            <div style={{maxWidth: colWidth-20}}>
                                <TeamStandings
                                    {...standings}
                                    teamId={team}
                                    tours={tours ? Object.keys(tours).map(k => ({
                                        label: k === 'active' ? 'Текущие' : 'Завершенные',
                                        items: tours[k].map(t => ({
                                            label: `${t.name} (${t.league}, ${t.season})`,
                                            _id: t._id
                                        }))
                                    })) : null}
                                />

                                <div ref={seriesRef} style={{width: colWidth-20}}>
                                    <SeriesStack
                                        scope='team'
                                        subjectId={team}
                                        title='Клубные рекорды'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
}

export default Club
